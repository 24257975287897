import React from "react";
import Breadcrumb from "@/components/Common/Breadcrumb";
import Layout from "@/components/Layout";
import SEO from "@/components/seo";
import { useLocation } from "@reach/router";
import { graphql, useStaticQuery } from "gatsby";
import { type TeamProps } from "@/types";
import TeamDetails from "@/components/About/Team/Details";

const TeamDetailsP = ({ params }: any) => {
  const { slug } = params;
  const data = useStaticQuery(graphql`
    query {
      allTeamJson {
        edges {
          node {
            id
            name
            position
            phone
            email
            imageDetails
            slug
            bio
          }
        }
      }
    }
  `);

  const tm = data.allTeamJson.edges.find(
    (edge: { node: TeamProps }) => edge.node.slug === slug,
  )?.node;

  return (
    <Layout>
      <SEO title="Team Details" canonical={`${useLocation().host}`} />
      <Breadcrumb name="Team Details" pathName="Team Details" />
      <TeamDetails tm={tm} />
    </Layout>
  );
};

export default TeamDetailsP;

import React from "react";
import { type TeamProps } from "@/types";
import { Link } from "gatsby";

interface Props {
  tm: TeamProps;
}
const TeamDetails = ({ tm }: Props) => {
  const cleanPhoneNumber = (phoneNumber: string) => {
    const spaceIndex = phoneNumber?.indexOf(" x");
    if (spaceIndex !== -1) {
      const cleanedPhoneNumber = phoneNumber?.substring(0, spaceIndex);
      return cleanedPhoneNumber;
    } else {
      return phoneNumber;
    }
  };

  return (
    <div className="rts-team-details rts-section-gap">
      <div className="container">
        <div className="row g-5">
          <div className="col-xl-6 col-lg-12 col-md-12">
            <div className="details-thumb">
              <img src={tm?.imageDetails} alt="Team Member" />
            </div>
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 pl--35 pl_sm--15">
            <div className="details">
              <div className="title-area">
                <span className="pre-title">{tm?.position}</span>
                <h3 className="title">{tm?.name}</h3>
              </div>
              <p className="disc">{tm?.bio}</p>
              <div className="team-details-support-wrapper">
                <i className="far fa-envelope"></i>
                <div className="support-innner">
                  <span>Email Address</span>
                  <a href={`mailto:${tm?.email}`}>
                    <h5 className="title">{tm?.email}</h5>
                  </a>
                </div>
              </div>
              <div className="team-details-support-wrapper">
                <i className="fal fa-phone-volume"></i>
                <div className="support-innner">
                  <span>Phone Number</span>
                  <a href={`tel:${cleanPhoneNumber(tm?.phone)}`}>
                    <h5 className="title">{tm?.phone}</h5>
                  </a>
                </div>
              </div>
              <div className="team-details-support-wrapper mb--45">
                <i className="far fa-map-marker-alt"></i>
                <div className="support-innner">
                  <span>Office Location</span>
                  <a
                    href="https://goo.gl/maps/wdvJCoMUwYcnqAR57"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <h5 className="title">
                      2775 NW 82 Ave, Doral, Florida 33122
                    </h5>
                  </a>
                </div>
              </div>
              <Link to="/contact" className="rts-btn btn-primary">
                Get in Touch
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
